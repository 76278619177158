<template>
	<div class="page_container">
		<div class="page_title_bg">
			<div class="page_title_flag" />
			<div class="page_title">关于我们</div>
		</div>
		<div class="page_about">
			<p class="page_about_desc_item" v-for="it of descs">{{it}}</p>
			<div class="page_about_imgs">
				<img class="page_about_img_item" src="@/assets/images/10105898.jpg" alt="" />
				<img class="page_about_img_item" src="@/assets/images/10059116.jpg" alt="" />
				<img class="page_about_img_item" src="@/assets/images/10019060.jpg" alt="" />
				<img class="page_about_img_item" src="@/assets/images/10019042.jpg" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
import { me_info } from '@/utils/info.js';

export default {
    name: 'About',
    data() {
        return {};
    },
	computed: {
		descs: () => me_info.descs,
	},
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.page_container{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 80px auto 0;
	max-width: 1200px;
	.page_title_bg{
		margin-top: 32px;
		display: flex;
		align-items: center;
		.page_title_flag{
			margin-right: 5px;
			width: 22px;
			height: 64px;
			background-color: #409eff;
		}
		.page_title{
			font-size: 42px;
			color:#444444; 
			font-family:Microsoft YaHei;
			font-weight: bold;
		}
	}
	.page_about{
		margin: 20px 0 30px;
		font-family: 'Microsoft YaHei';
		color: #000000;
		width: 100%;
		font-size: 22px;
		font-weight: normal;
		font-style: normal;
		text-decoration: none;
		text-align: left;
		text-indent: 2em; /* 设置首行缩进为两个字符的宽度 */
		line-height: 40px;
		word-wrap: break-word;
		word-break: normal;
		.page_about_desc_item{}
		.page_about_imgs{
			margin-top: 10px;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.page_about_img_item{
				width: calc(50% - 5px);
				margin-bottom: 10px;
			}
		}
	}
}

@media (max-width:940px) {
	.page_container{
		.page_title_bg{
			.page_title_flag{
				margin-left: 12px;
				width: 14px;
				height: 42px;
			}
			.page_title{
				font-size: 28px;
			}
		}
		.page_about{
			font-size: 18px;
			line-height: 32px;
			padding: 0 20px;
			width: calc(100% - 40px);
			.page_about_imgs{
				.page_about_img_item{
					width: calc(100% - 10px);
					margin-bottom: 10px;
				}
			}
		}
	}
}
</style>
